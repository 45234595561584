// import Button from 'components/common/button';
import Navbar from 'components/navbar';
import React from 'react';
// import learnmore from 'assets/learnmore.svg';
import ProductSection from 'components/product';
// import TestimonialSection from "components/testimonials";
import Newsletter from 'components/newsletter';
import { Slide } from 'react-reveal';
// import { Link } from 'react-router-dom';
import ServicesSection from 'components/about/services';

const Home = () => {
  return (
    <div className=" mx-auto 2xl:container pt-4 lg:pt-6  font-satoshi">
      <div className=" lg:px-24  px-5">
        <Navbar />
        <div className="grid lg:grid-cols-2 lg:gap-14 grid-cols-1 items-center mt-8">
          <Slide top cascade>
            <div className="lg:order-1 order-2">
              <h1 className="text-[#0B1B35] lg:text-[70px] text-[35px]  font-[900] mb-4">
                Introducing 3XG:
              </h1>
              <p className="text-[20px] fomt-[500] text-[#585858] lg:mb-[150px] mb-10">
                3XG Technologies is an innovative technology company that
                specializes in providing comprehensive IT solutions for
                businesses of all sizes. Our core services include; Software
                development, web designing and development, website and email
                hosting, network design and installation, software and antivirus
                supply, general IT products supply, consultancy and management
                information systems.
              </p>
              {/* <div className="flex my-14 items-center lg:gap-12 gap-4">
                <Link to={'/about'}>
                  <Button>Get Started</Button>
                </Link>
                <div className="flex items-center gap-4 font-bold text-lg cursor-pointer hover:underline ">
                  <img src={learnmore} height={60} width={50} alt="learnmore" />
                  Watch Video
                </div>
              </div> */}
            </div>
          </Slide>
          <Slide right cascade>
            <div className="bg-hero lg:h-[630px]  h-[400px] bg-cover order-1 lg:order-2"></div>
          </Slide>
        </div>
      </div>
      <ServicesSection />
      <ProductSection />
      {/* <TestimonialSection /> */}
      <Newsletter />
    </div>
  );
};

export default Home;
