import Navbar from 'components/navbar';
import React from 'react';
// import learnmore from 'assets/learnmore.svg';
import Newsletter from 'components/newsletter';
import AboutSection from 'components/about';
import { Slide } from 'react-reveal';
// import TeamSection from 'components/team';
import successrate from 'assets/successrate.svg';
import happyclients from 'assets/happyclients.svg';
import MissionVision from 'components/about/mission-vision';
import OurStory from 'components/about/our-story';
import CoreValues from 'components/about/core-value';

const About = () => {
  return (
    <div className=" mx-auto 2xl:container pt-4 lg:pt-6  font-satoshi">
      <div className=" lg:px-24  px-10">
        <Navbar />
        <div className="grid grid-cols-1 items-center mt-8 mb-10 relative">
          <Slide top cascade>
            <div>
              <h1 className="text-[#0B1B35] lg:text-[70px] text-[35px]  font-[900] mb-4">
                About Us{' '}
              </h1>
              <img
                src={successrate}
                alt="successrate"
                className="lg:absolute lg:block right-0 top-0 hidden"
              />
              <p className="text-[20px] fomt-[500] text-[#585858] mb-8">
                3XG is a limited liability company registered in 2020 with the
                Corporate Affairs Commission as a technology service provider
                which includes, development, supplies, agency and consultancy.
              </p>
              <p className="text-[20px] fomt-[500] text-[#585858] lg:mb-32">
                We are one of the emerging technology companies in Nigeria and
                Africa that provides global standard technology products and
                services to SMEs, enterprise organisations, institutions and
                many more. We have professionals who are innovative and creative
                in providing these technology solutions.
              </p>
            </div>
            <img
              src={happyclients}
              alt="happyclients"
              className="lg:absolute lg:block -bottom-[40%] -left-20 hidden"
            />
          </Slide>
        </div>
      </div>
      <AboutSection />
      <MissionVision />
      <CoreValues />
      <OurStory />
      {/* <div className="bg-[#fff] lg:px-24 py-20  p-12 font-satoshi ">
        <p className="text-center tracking-[.45em] font-bold text-[#8A898E]">
          THE TEAM{' '}
        </p>
        <h6 className="text-center text-[#0B1B35] font-bold lg:text-[42px] text-[21px]">
          Meet Our Team{' '}
        </h6>
        <TeamSection />
      </div> */}
      <Newsletter />
    </div>
  );
};

export default About;
