import React from 'react';
import activity from 'assets/activity.svg';
import heart from 'assets/heart.svg';
import star from 'assets/star.svg';
// import Button from 'components/common/button';
import { Fade } from 'react-reveal';

const AboutSection = () => {
  return (
    <div className="bg-[#FFF3EF] lg:px-24 lg:pb-0  p-12 font-satoshi ">
      <p className="text-center tracking-[.45em] font-bold text-[#8A898E]">
        WHAT WE ARE{' '}
      </p>
      <h6 className="text-center text-[#0B1B35] font-bold lg:text-[42px] text-[21px]">
        Our Goal{' '}
      </h6>
      <div className="lg:mt-20 mt-10  grid lg:grid-cols-3 grid-cols-1 lg:gap-20 gap-8 text-center">
        <Fade bottom cascade>
          {data?.map((item, i) => (
            <div
              className="bg-white lg:p-10 p-5 rounded-[28px] shadow-card"
              key={i}
            >
              <div className="flex justify-center">
                <img
                  src={item?.icon}
                  height={77}
                  width={77}
                  alt="activity"
                  className="lg:h-[77px] h-[35px]"
                />
              </div>
              <p className="lg:text-[27px] text-[18px] text-[#000000] font-bold my-6">
                {item?.title}
              </p>
              <p className="text-[#585858] lg:text-[18px] text-[14px] ">
                {item?.subtitle}
              </p>
              {/* <Button className={item?.className}>Learn more</Button> */}
            </div>
          ))}{' '}
        </Fade>
      </div>
    </div>
  );
};

const data = [
  {
    icon: activity,
    title: 'Delivering Happiness',
    subtitle: `You'll experience a new era of digital excellence.
    We pride ourselves on offering a robust and secure infrastructure, ensuring your 100% happiness`,
    link: '/about',
    className: 'mt-16',
  },
  {
    icon: heart,
    title: 'Seamless Products',
    subtitle: `Our product offerings effortlessly connects with leading technologies, empowering you to streamline your operations and maximize efficiency. Whether it's inventory management, order fulfillment, or shipping logistics.`,
    link: '/about',
    className: 'mt-10',
  },
  {
    icon: star,
    title: 'Digital Excellence',
    subtitle: `From setting up your online logistics to optimising your supply flow, our products are equipped with relevant technologies you need to make life easier`,
    link: '/about',
    className: 'mt-24',
  },
];

export default AboutSection;
