import React from 'react';
import story1 from 'assets/story1.png';
import story2 from 'assets/story2.png';
import story3 from 'assets/story3.png';
import { Slide } from 'react-reveal';

const OurStory = () => {
  return (
    <div className="lg:mb-20">
      <p className="text-center tracking-[.45em] font-bold text-[#8A898E]">
        HOW WE STARTED{' '}
      </p>
      <h6 className="text-center text-[#0B1B35] font-bold lg:text-[42px] text-[21px]">
        Our Story{' '}
      </h6>
      {data?.map((item, i) => (
        <div
          className={`lg:px-24 lg:gap-24 lg-6  p-12 font-satoshi grid items-center ${
            i % 2 ? 'lg:grid-cols-[35%_1fr]' : 'lg:grid-cols-[1fr_35%] '
          }`}
          key={i}
        >
          <div className={i % 2 ? 'order-2  text-right' : 'lg:order-1 order-2'}>
            <div className={i % 2 ? 'flex justify-end mr-8' : 'ml-8'}>
              <div className="w-[60px] h-[60px] custom-clip-path flex items-center justify-center ">
                <div class="text-white text-lg font-bold mt-8 mr-2">
                  0{i + 1}
                </div>
              </div>
            </div>

            <h1 className="text-[#0B1B35] lg:text-[27px] text-[20px]  font-[900] my-6">
              {item?.title}
            </h1>
            <p className="lg:text-[20px] leading-8 text-base fomt-[500] text-[#585858] mb-6">
              {item?.paragraph1}
            </p>

            <p className="lg:text-[20px] leading-8 text-base fomt-[500] text-[#585858] ">
              {item?.paragraph2}
            </p>
          </div>
          <Slide right cascade>
            <img
              src={item?.icon}
              alt={item?.icon}
              className={i % 2 ? 'lg:order-1' : 'lg:order-2 order-1'}
            />
          </Slide>
        </div>
      ))}
    </div>
  );
};

const data = [
  {
    icon: story1,
    title: 'In the Early Days...',
    paragraph1: `3XG Technologies was started out of a vision to build a company that
    could help businesses of all sizes leverage technology to achieve
    their objectives. From the very beginning, we were committed to
    building a company culture that was rooted in integrity, innovation,
    and collaboration. We believed that by putting customers first and
    always staying ahead of the curve, we could build a successful and
    sustainable business.`,
    paragraph2: `In the early days, 3XG Technologies focused on providing IT services
    and hardware supplies to local businesses. But as we grew, we began
    to expand our offerings to include application development, cloud
    solutions, and other cutting-edge technologies.`,
  },
  {
    icon: story2,
    title: 'As time went on...',
    paragraph1: `One of the key factors that set 3XG Technologies apart from its competitors was its commitment to providing customized solutions that were tailored to meet the unique needs of each customer. We knew that every business was different, and we took the time to understand each customer's unique goals and challenges before recommending solutions.`,
    paragraph2: `Over time, 3XG Technologies has become known as a reliable and trusted partner for businesses across different verticals. We earned a reputation for delivering high-quality work, and our customers know that they could always count on us to provide exceptional service.`,
  },
  {
    icon: story3,
    title: 'The Future...',
    paragraph1: `As the company continued to grow, we remained focused on our core values, which included innovation, integrity, customer-first, collaboration, agility, and accountability. We understand that the technology landscape is constantly changing, and we are committed to staying ahead of the curve.`,
    paragraph2: `We are excited about the future of technology and the role that 3XG Technologies will play in shaping it. With our commitment to excellence and our passion for innovation, we are confident that we will continue to make a difference in the lives of our customers for years to come.`,
  },
];

export default OurStory;
