import Button from 'components/common/button';
import React from 'react';
import arrow from 'assets/arrow.svg';
import arrow2 from 'assets/arrow2.svg';
import { Slide } from 'react-reveal';
import { Link } from 'react-router-dom';

const ProductSection = () => {
  return (
    <div className="bg-[#fff] lg:px-24 py-20  p-12 font-satoshi ">
      <p className="text-center tracking-[.45em] font-bold text-[#8A898E]">
        PRODUCTS{' '}
      </p>
      <h6 className="text-center text-[#0B1B35] font-bold lg:text-[42px] text-[21px]">
        Our Product Offerings{' '}
      </h6>

      <div className="lg:flex grid gap-10 grid-cols-1 items-center mt-8">
        <Slide right cascade>
          <div className="relative lg:w-[30%] lg:order-1 order-2">
            <h1 className="text-[#0B1B35] lg:text-[65px] text-[30px]  font-[900] mb-0">
              iTranxit{' '}
            </h1>
            <p className="text-[14px] font-[500] text-[#585858] lg:mb-12 mb-4">
              iTranxit is a logistics technology firm that creates value by
              giving access to customers/businesses to be able to make the
              choice of logistics that is cost-effective with a high level of
              operational efficiency based on their business models.
            </p>
            <Link target="_blank" to={'https://itranxit.com/'}>
              <Button>Learn more</Button>
            </Link>
            <img
              src={arrow}
              alt="arrow"
              className="lg:absolute -right-28 top-20 hidden"
            />
          </div>
        </Slide>
        <div className=" order-1 lg:order-2 bg-productImg h-[200px] lg:h-[550px] lg:w-[70%] lg:bg-cover bg-contain bg-no-repeat bg-right-bottom"></div>
      </div>

      <div className="lg:flex gap-10 grid-cols-1 items-center lg:mt-24 mt-8">
        <div className="bg-productImg2 lg:bg-[background-position-x:-150px] lg:h-[580px] h-[200px] lg:w-[70%] mb-14 bg-contain bg-center  bg-no-repeat"></div>
        <Slide left cascade>
          <div className="relative lg:w-[30%]">
            <h1 className="text-[#0B1B35] lg:text-[65px] text-[30px]  font-[900] mb-0">
              HireNex
            </h1>
            <p className="text-[14px] font-[500] text-[#585858] lg:mb-12 mb-4">
              HireNex is a digital platform that links tech recruiters and
              talent, assisting recruiters and hiring managers in finding the
              best digital talent across Africa.{' '}
            </p>
            <Link to={'/#'}>
              <Button>Learn more</Button>
            </Link>
            <img
              src={arrow2}
              alt="arrow"
              className="lg:absolute -left-0 -bottom-60 hidden"
            />
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default ProductSection;
