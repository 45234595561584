import React from "react";

const Button = ({ children, className }) => {
  return (
    <button
      className={`inline-block px-6 text-lg py-[10px] text-white bg-gradient-to-b from-[#FD9463] to-[#EA6565] rounded-[10px] hover:bg-gradient-to-b hover:from-[#EA6565] hover:to-[#FD9463] ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
