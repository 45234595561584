import React, { useRef } from 'react';
import service1 from 'assets/service1.svg';
import service2 from 'assets/service2.svg';
import service3 from 'assets/service3.svg';
import service4 from 'assets/service4.svg';
import service5 from 'assets/service5.svg';
import service6 from 'assets/service6.svg';
import service7 from 'assets/service7.svg';
import service8 from 'assets/service8.svg';
import service9 from 'assets/service9.svg';
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Fade } from 'react-reveal';
import { Icon } from '@iconify/react';

const ServicesSection = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="bg-[#FFF3EF] lg:px-8 lg:pb-20 p-12 px-8 font-satoshi relative myswiperbox">
      <p className="text-center tracking-[.45em] font-bold text-[#8A898E]">
        ABOUT US{' '}
      </p>
      <h6 className="text-center text-[#0B1B35] font-bold lg:text-[42px] text-[21px]">
        Our Service Offerings{' '}
      </h6>
      <div ref={prevRef} className="swiper-button-prev ">
        <Icon
          icon="gg:arrow-right"
          width={30}
          className="lg:w-full w-[50%] text-[#EA6565]"
          hFlip={true}
        />
      </div>
      <div ref={nextRef} className="swiper-button-next">
        <Icon
          icon="gg:arrow-right"
          width={30}
          className="lg:w-full w-[50%] text-[#EA6565]"
        />
      </div>
      <Swiper
        modules={[Navigation, A11y, Autoplay]}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        className="myswiper lg:mt-20 mt-10 relative"
        autoplay={{
          delay: 10000,
          disableOnInteraction: true,
        }}
        breakpoints={{
          // When window width is >= 1024px (lg)
          1024: {
            slidesPerView: 3,
          },
          // When window width is >= 768px (sm)
          768: {
            slidesPerView: 2,
          },
          // When window width is < 768px (sm)
          0: {
            slidesPerView: 1,
          },
        }}
        spaceBetween={30}
      >
        {data?.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="lg:h-[430px] md:h-[310px] h-fit text-center">
              <div
                className="bg-white lg:p-10 p-5 rounded-[28px] h-full"
                key={i}
              >
                <Fade bottom cascade>
                  <div className="flex justify-center">
                    <img
                      src={item?.icon}
                      height={77}
                      width={77}
                      alt="activity"
                      className="lg:h-[77px] h-[35px]"
                    />
                  </div>
                  <p className="lg:text-[23px] text-[16px] text-[#000000] font-bold my-5">
                    {item?.title}
                  </p>
                  <p className="text-[#585858] lg:text-[16px] text-[13px] ">
                    {item?.subtitle}
                  </p>
                </Fade>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const data = [
  {
    icon: service1,
    title: 'Software Development',
    subtitle: `We develop custom software solutions to meet the specific needs of businesses. This includes all stages of the software development process, from initial concept and design to testing, deployment, and ongoing maintenance and support. The application can be developed for desktop, web, or mobile platforms, and can include everything from simple applications to complex enterprise systems.`,
  },
  {
    icon: service2,
    title: 'Web Design and Development',
    subtitle: `We create custom websites for businesses, including all aspects of the design and development process. This includes designing the user interface and user experience, creating the layout and visual elements, developing the functionality and features, and integrating content and media. The end result is a website that is not only visually appealing, but also functional, responsive, and user-friendly.`,
  },
  {
    icon: service3,
    title: 'Website Hosting',
    subtitle: `We help businesses to host their website on a server to ensure that it is accessible to users 24/7. This includes providing reliable and secure hosting services, ensuring that the website is optimized for speed and performance, and providing backup and disaster recovery services to minimize downtime and data loss.`,
  },
  {
    icon: service4,
    title: 'General IT products Supply ',
    subtitle: `This service involves supplying businesses with a wide range of IT products, including hardware components, peripherals, and accessories. This includes everything from desktops, laptops, and servers, to printers, scanners, and networking equipment.`,
  },
  {
    icon: service5,
    title: 'Consultancy',
    subtitle: `This service involves providing expert advice and guidance to businesses on a wide range of technology-related issues. This includes everything from developing a technology strategy and implementing new systems to troubleshooting existing problems and identifying opportunities for improvement. The goal is to help businesses leverage technology to achieve their goals and stay ahead of the competition.`,
  },
  {
    icon: service6,
    title: 'Management Information System',
    subtitle: `This service involves designing, implementing, and managing information systems that are specifically tailored to meet the needs of businesses. This includes systems for managing customer relationships, inventory, finances, and other critical business functions. The end result is a set of integrated systems that provide businesses with the data and insights they need to make informed decisions and stay competitive in their markets.`,
  },
  {
    icon: service7,
    title: 'Email Hosting',
    subtitle: `This service involves hosting a business's email accounts on a server to ensure that they are secure, reliable, and accessible from anywhere.
    This includes setting up email accounts, configuring email clients, providing spam filtering and virus protection, and providing backup and disaster recovery services.`,
  },
  {
    icon: service8,
    title: 'Network Design and Installation',
    subtitle: `We design and install the business's computer network, including all hardware and software components. This includes selecting and configuring servers, routers, switches, and other network equipment, as well as setting up security protocols, firewalls, and other protective measures. The end result is a secure and efficient network that can support the business day-to-day operations.`,
  },
  {
    icon: service9,
    title: 'Software and Antivirus Supply',
    subtitle: `We supply businesses with the latest software and antivirus solutions to ensure that their systems are up-to-date and protected against potential security threats. This includes providing licenses for popular software programs such as Microsoft Office, Adobe Creative Suite, and antivirus software such as Norton, McAfee, and Kaspersky.`,
  },
];

export default ServicesSection;
