import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from 'pages/index';
import About from 'pages/about';
import Products from 'pages/products';
import NotFoundPage from 'pages/notfound';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// git push origin main - 3xg.africa
// git push github main - to my personal repo (netlify) - https://3xg.netlify.app/

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
