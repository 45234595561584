import React from 'react';
import mission from 'assets/mission.png';
import { Slide } from 'react-reveal';

const MissionVision = () => {
  return (
    <div className="bg-[#fff] lg:px-24 lg:py-24 gap-24  p-12 font-satoshi grid lg:grid-cols-[40%_1fr] items-center">
      <div>
        <h1 className="text-[#0B1B35] lg:text-[52px] text-[28px]  font-[900] mb-4">
          Our Vision{' '}
        </h1>
        <p className="lg:text-[20px] leading-8 text-base fomt-[500] text-[#585858] mb-20">
          Our vision is to become the most trusted and preferred IT partner for
          businesses worldwide. We believe in providing innovative and
          sustainable solutions that help our clients achieve their business
          goals.
        </p>
        <h1 className="text-[#0B1B35] lg:text-[52px] text-[28px]  font-[900] mb-4">
          Our Mission{' '}
        </h1>
        <p className="lg:text-[20px] leading-8 text-base fomt-[500] text-[#585858] ">
          Our mission is to provide cost-effective and efficient IT solutions to
          businesses of all sizes. We believe in developing long-term
          relationships with our clients and helping them leverage technology to
          achieve their objectives.
        </p>
      </div>
      <Slide right cascade>
        <img src={mission} alt="mission" />
      </Slide>
    </div>
  );
};

export default MissionVision;
