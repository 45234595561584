import Button from 'components/common/button';
import Navbar from 'components/navbar';
import React from 'react';
import appstore from 'assets/appstore.png';
import googleplay from 'assets/googleplay.png';
import app from 'assets/app.svg';
import Newsletter from 'components/newsletter';
import { Slide, Zoom } from 'react-reveal';
import { Link } from 'react-router-dom';

const Products = () => {
  return (
    <div className=" mx-auto 2xl:container pt-4 lg:pt-6  font-satoshi">
      <div className=" lg:px-24  px-10">
        <Navbar />
        <div className="lg:flex grid items-center my-8">
          <Slide top cascade>
            <div className="lg:w-[46%] lg:order-1 order-2">
              <h1 className="text-[#0B1B35] lg:text-[70px] text-[35px]  font-[900] mb-4">
                iTranxit{' '}
              </h1>
              <p className="lg:text-[20px] text-base fomt-[500] text-[#585858] mb-8">
                iTranxit is a logistics technology firm that creates value by
                giving access to customers/businesses to be able to make the
                choice of logistics that is cost-effective with a high level of
                operational efficiency based on their business models.
              </p>
              <p className="lg:text-[20px] text-base fomt-[500] text-[#585858]">
                Our vision is to build the largest L-Commerce for Africa and
                Africans. We are creating a connected loop for both intra and
                inter-logistics within African regions.
              </p>
              <Link target="_blank" to={'https://itranxit.com/'}>
                <Button className="lg:my-14 my-8">Get Started</Button>
              </Link>
            </div>
          </Slide>
          <Slide right cascade>
            <div className="lg:w-[54%] lg:order-2 order-1">
              <div className="bg-hero-product lg:h-[600px]  bg-[background-position-x:-150px]  bg-[background-position-y:-60px]  h-[300px] lg:bg-cover bg-contain bg-no-repeat order-1 lg:order-2"></div>
            </div>{' '}
          </Slide>
        </div>
      </div>

      <div className="bg-[#FFF3EF] lg:px-24  p-12 font-satoshi ">
        <p className="text-center tracking-[.45em] font-bold text-[#8A898E]">
          GET OUR MOBILE APP{' '}
        </p>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 lg:mt-20 mt-12">
          <div className="order-2 lg:order-1">
            <h6 className="text-[#0B1B35] lg:text-[48px] text-[24px] font-[900] mb-4">
              Download our Mobile App
            </h6>
            <p className="text-[20px] text-[#585858] pr-8">
              {/* Experience hassle-free logistics at your fingertips. Download our
              mobile app now for seamless tracking, easy booking, and convenient
              access to our e-commerce and social commerce-specific services. */}
              Experience our seamless and intuitive Mobile interface.
            </p>
            <div className="flex items-center gap-8 lg:mt-36 mt-8">
              <img
                src={googleplay}
                width={200}
                className="lg:w-[200px] w-[40%]"
                alt="googleplay"
              />
              <img
                src={appstore}
                width={220}
                className="lg:w-[220px] w-[45%]"
                alt="appstore"
              />
            </div>
          </div>
          <div className="lg:order-2 order-1">
            <Zoom>
              <img src={app} width={'100%'} alt="app" />
            </Zoom>
          </div>
        </div>
      </div>
      <div className=" lg:px-24  px-10">
        <div className="lg:flex grid items-center my-8">
          <Slide top cascade>
            <div className="lg:w-[46%] lg:order-1 order-2">
              <h1 className="text-[#0B1B35] lg:text-[70px] text-[35px]  font-[900] mb-4">
                Hirenex{' '}
              </h1>
              <p className="lg:text-[20px] text-base fomt-[500] text-[#585858] mb-8">
                HireNex is a digital platform that links tech recruiters and
                talent, assisting recruiters and hiring managers in finding the
                best digital talent across Africa.
              </p>
              <p className="lg:text-[20px] text-base fomt-[500] text-[#585858] mb-8">
                This platform addresses the various difficulties and issues that
                both job seekers and businesses encounter throughout the
                conventional hiring process. More than just matching talents to
                job descriptions, HireNex wants to help in finding the proper
                fit for a business.
              </p>

              <p className="lg:text-[20px] text-base fomt-[500] text-[#585858]">
                HireNex platform places a strong emphasis on developing
                connections that go beyond a cursory resume review and places a
                high weight on cultural fit and shared values.
              </p>
              {/* <Link target="_blank" to={'https://itranxit.com/'}> */}
              <Button className="lg:my-14 my-8">Go to Website</Button>
              {/* </Link> */}
            </div>
          </Slide>
          <Slide right cascade>
            <div className="lg:w-[54%] lg:order-2 order-1">
              <div className="bg-hero-product2 lg:h-[600px]  bg-[background-position-y:60px]  h-[300px] w-full lg:bg-contain bg-contain bg-no-repeat order-1 lg:order-2"></div>
            </div>{' '}
          </Slide>
        </div>
      </div>
      <Newsletter bgColor={'white'} />
    </div>
  );
};

export default Products;
