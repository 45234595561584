import React from 'react';
import star from 'assets/star.svg';
import icon1 from 'assets/icon1.svg';
import icon2 from 'assets/icon2.svg';
import icon3 from 'assets/icon3.svg';
import icon4 from 'assets/icon4.svg';
import icon5 from 'assets/icon5.svg';
import { Slide } from 'react-reveal';

const CoreValues = () => {
  return (
    <div>
      <div className="w-full h-[100%] lg:mb-20 mb-12 lg:bg-clip-path bg-[#FD9463] lg:px-24 lg:pb-24 p-12 font-satoshi">
        <div class="text-lg font-bold lg:pt-10 mr-2">
          <p className="lg:text-right text-center tracking-[.45em] text-sm font-bold text-[#fff] mb-4">
            WHAT WE STAND FOR{' '}
          </p>
          <h6 className="lg:text-right text-center text-[#fff] font-bold lg:text-[42px] text-[21px]">
            Our Core Values{' '}
          </h6>
          <Slide bottom cascade>
            <div className="grid lg:grid-cols-3 lg:gap-14 gap-10 lg:mt-20 mt-12">
              {data?.map((item, i) => (
                <div
                  className="custom-box-shadow p-6 rounded-3xl bg-white"
                  key={i}
                >
                  <img
                    src={item?.icon}
                    height={30}
                    width={30}
                    alt="icon"
                    className="flex mx-auto"
                  />
                  <p className="font-bold text-[20px] my-4 text-center">
                    {item?.name}
                  </p>
                  <p className="text-base text-[#585858] font-[500] mt-4 text-justify">
                    {item?.desc}
                  </p>
                </div>
              ))}
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export const data = [
  {
    name: 'Innovation',
    icon: icon1,
    desc: `We strive to constantly innovate and push the boundaries of what
  is possible, by leveraging cutting-edge technology and creative
  thinking.`,
  },
  {
    name: 'Collaboration',
    icon: icon2,
    desc: `We believe in the power of collaboration and teamwork, and we work closely with our customers, partners, and employees to achieve common goals.`,
  },
  {
    name: 'Accountability',
    icon: star,
    desc: `We take full responsibility for our actions and decisions, and we are committed to delivering high-quality work and results.`,
  },
  {
    name: 'Integrity',
    icon: icon3,
    desc: `We conduct our business with the highest ethical standards, treating our customers, employees, and partners with honesty, fairness, and respect.`,
  },
  {
    name: 'Customer-First',
    icon: icon4,
    desc: `Our customers are at the heart of everything we do, and we are committed to understanding their needs and exceeding their expectations.`,
  },
  {
    name: 'Agility',
    icon: icon5,
    desc: `We are agile and adaptable, and we are able to respond quickly to changing customer needs and market trends.`,
  },
];

export default CoreValues;
