import React from 'react';
import email from 'assets/email.svg';
import Button from 'components/common/button';
import { useNavigate } from 'react-router-dom';

const Newsletter = (bgColor) => {
  let navigate = useNavigate();
  return (
    <div
      id="newsletter"
      className={`${
        bgColor ? bgColor : 'bg-[#FFF3EF]'
      } lg:px-24  px-12 py-14  font-satoshi `}
    >
      <p className="text-center tracking-[.45em] font-bold text-[#8A898E]">
        FOLLOW OUR JOURNEY{' '}
      </p>
      <h6 className="text-center text-[#0B1B35] font-bold lg:text-[42px] text-[21px]">
        Contact Us
      </h6>

      <div className="lg:flex items-end justify-center gap-14 lg:mt-20  mt-14">
        <div className="flex justify-center lg:mb-0 mb-8">
          <img src={email} className="lg:h-[367px] h-[100px]" alt="email" />
        </div>
        <div className="mb-12">
          <p className="text-[#585858] text-[18px] ">
            For product offerings, updates and discounts, please contact us!
          </p>
          <input
            placeholder="Name"
            className="lg:w-[80%] w-full border-[1px] py-2 px-4 shadow-card mt-6 rounded-[49px]"
            type="text"
          />
          <input
            placeholder="E-mail"
            className="lg:w-[80%] w-full border-[1px] py-2 px-4 shadow-card mt-4 rounded-[49px] focus:outline-none"
            type="email"
          />
          <input
            placeholder="Phone no."
            className="lg:w-[80%] w-full border-[1px] py-2 px-4 shadow-card mt-4 rounded-[49px]"
            type="text"
          />
          <input
            placeholder="Message"
            className="lg:w-[80%] w-full border-[1px] py-2 px-4 shadow-card mt-4 mb-10 rounded-[49px]"
            type="text"
          />
          <Button className={'lg:w-[80%] w-full'}>Submit</Button>
        </div>
      </div>
      <div className="lg:flex lg:justify-between lg:items-center text-center text-[#1E0E62] font-dmsans mt-20">
        <p className="">
          © {new Date().getFullYear()} 3XG. All rights reserved.
        </p>
        <div className="flex justify-center lg:justify-normal gap-8">
          <div
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/products');
            }}
            className="cursor-pointer hover:underline"
          >
            Products
          </div>
          <div
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/about');
            }}
            className="cursor-pointer hover:underline"
          >
            About Us
          </div>
        </div>
      </div>
      <div className="h-[2px] mt-8 mb-4 bg-[#EBEAED]" />
    </div>
  );
};

export default Newsletter;
