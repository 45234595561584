import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import logo from 'assets/logo.svg';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';

const Navbar = () => {
  const location = useLocation();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };

  const renderLinks = () => (
    <>
      {navItems?.map((item, i) => (
        <Link
          to={item?.path}
          key={i}
          className={`${
            item?.path === location?.pathname
              ? 'text-[#EA6565] font-[900]'
              : 'text-[#000000] font-[500]'
          } lg:auto block text-center text-lg`}
        >
          {item?.title}
        </Link>
      ))}
    </>
  );

  return (
    <>
      <div className="lg:flex items-center justify-between">
        <div className="flex justify-between items-center">
          <img
            src={logo}
            height={108}
            width={114}
            className="w-[50px] lg:w-[114px]"
            alt="logo"
          />
          {/* Mobile Navigation Toggle */}
          <div
            className="lg:hidden cursor-pointer"
            onClick={handleMobileNavToggle}
          >
            {/* Hamburger Icon */}
            <div className="w-6 h-px bg-gray-900 my-1"></div>
            <div className="w-6 h-px bg-gray-900 my-1"></div>
            <div className="w-6 h-px bg-gray-900 my-1"></div>
            <div className="w-6 h-px bg-gray-900 my-1"></div>
          </div>
        </div>
        <motion.div
          className={`lg:hidden  ${
            isMobileNavOpen ? 'fixed inset-0  z-50 bg-white  ' : 'hidden'
          }`}
          initial="closed"
          animate={isMobileNavOpen ? 'open' : 'closed'}
          variants={variants}
        >
          <div className="flex justify-between items-center px-10 mt-8">
            <img
              src={logo}
              height={108}
              width={114}
              className="w-[50px] lg:w-[114px]"
              alt="logo"
            />
            {/* Mobile Navigation Close Toggle */}
            <div
              className="lg:hidden cursor-pointer"
              onClick={handleMobileNavToggle}
            >
              <Icon
                icon={'carbon:close-filled'}
                className="text-gray-900"
                height={25}
              />
            </div>
          </div>
          <div className="gap-6 flex flex-col mt-28"> {renderLinks()}</div>
          <div className="mt-20 flex justify-center">
            <ScrollLink
              to="newsletter"
              smooth={true}
              duration={500}
              className="cursor-pointer px-6 text-lg py-[10px] text-white bg-gradient-to-b from-[#FD9463] to-[#EA6565] rounded-[10px] hover:bg-gradient-to-b hover:from-[#EA6565] hover:to-[#FD9463]"
            >
              Contact Us
            </ScrollLink>
          </div>
        </motion.div>

        <div className="lg:flex hidden items-center gap-8 font-satoshi">
          {renderLinks()}
        </div>

        <ScrollLink
          to="newsletter"
          smooth={true}
          duration={500}
          className="cursor-pointer lg:inline-block hidden px-6 text-lg py-[10px] text-white bg-gradient-to-b from-[#FD9463] to-[#EA6565] rounded-[10px] hover:bg-gradient-to-b hover:from-[#EA6565] hover:to-[#FD9463]"
        >
          Contact Us
        </ScrollLink>
      </div>
    </>
  );
};

const navItems = [
  { title: 'Home', path: '/' },
  { title: 'About Us', path: '/about' },
  { title: 'Products', path: '/products' },
];

export default Navbar;
